/**
 * Format Game & DLC Related data
 */
export function getGameData(data) {
  const gameData = data.game ? data.game.edges[0].node : null
  const stores = gameData.stores ? gameData.stores : null

  // Game Stores links
  let gameLinks = []
  const mainGame = gameData.mainGame ? gameData.mainGame : null
  mainGame.forEach((product) => {
    gameLinks.push({ ...product, type: 'main-game' })
  })
  const dlcs = gameData.dlc ? gameData.dlc : null
  dlcs.forEach((product) => {
    gameLinks.push({ ...product, type: 'dlc' })
  })

  // CTA data
  const buySection = gameData.buyGameSection ? gameData.buyGameSection : null

  // Promotion Data
  const promoData = gameData?.promotions ? gameData?.promotions : null

  const formatedGameData = {
    buySection: buySection,
    gameLinks: gameLinks,
    promos: {
      discounts: promoData,
    },
    stores: stores,
  }

  return formatedGameData
}
