import React from 'react'

import EarlyAccessForm from './early-access-form'

const FormModal = (props) => {
  const globalData = props.globalData
  const pageContext = props.pageContext

  const theme = pageContext.theme.siteTheme.content.Newsletter
  const newsletterFormStyles = theme && theme.Form ? theme.Form : undefined
  const newsletterData = globalData?.globalData?.earlyDemoForm
  const ageGateData = globalData?.globalData?.ageGate
  let error = globalData.stringsData?.errors
  error = { ...error, newsletterSuccess: newsletterData?.confirmationText }
  const dates = globalData.stringsData?.dates

  return (
    <div className={`form-modal relative`}>
      <div className="newsletter-content relative">
        <div className="newsletter-form-wrapper">
          <EarlyAccessForm
            pageContext={pageContext}
            strings={{ error, dates }}
            data={{ newsletterData, ageGateData }}
            styling={newsletterFormStyles}
            list={'islandsofinsight'}
            type="modal"
            dataLayer={{
              ...pageContext.dataLayer.newsletter,
              eventAction: 'newsletter subscription',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default FormModal
