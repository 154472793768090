export function iterableHandler(
  formData,
  currentLocale,
  currentCountry,
  lead,
  list
) {
  const isBrowser = typeof window !== 'undefined'
  const browserLocale = isBrowser ? window.navigator.language : currentLocale
  const signupSourceURL = isBrowser ? window.location.pathname : ''
  const signupSourceFullURL = isBrowser ? window.location.href : ''
  const signupSourceDomain = isBrowser ? window.location.hostname : ''

  let lambdaEndpoint =
    process.env.GATSBY_ENV === 'prod' ? 'https://v6l6p2bgcziw3pdxbibmnjo4sm0wkdfd.lambda-url.us-east-1.on.aws/' // Production Endpoint
      : process.env.GATSBY_ENV === 'stg' ? 'https://flwkl534un7fedkey6ecwz2ej40mutkh.lambda-url.us-east-1.on.aws/' // Staging Endpoint
        : 'https://huqeylquw6ai5bukusupf7wheu0ihteq.lambda-url.us-east-1.on.aws/' // Development Endpoint

  if (process.env.GATSBY_ITERABLE_LAMBDA) {
    lambdaEndpoint = process.env.GATSBY_ITERABLE_LAMBDA
  }

  const formatedData = JSON.stringify({
    list: list,
    email: formData.EMAIL,
    dataFields: {
      locale: currentLocale,
      browserLocale: browserLocale,
      consent: formData.consent,
      agreement: formData.agreement,
      wishlist: formData.wishlist,
      country: currentCountry,
      signupSourceURL: signupSourceURL,
      signupSourceFullURL: signupSourceFullURL,
      signupSourceDomain: signupSourceDomain,
      medium: lead && lead.medium ? lead.medium : 'direct',
      queryString: lead && lead.result ? lead.result : null,
    },
  })

  const requestOptions = {
    method: 'POST',
    body: formatedData,
    redirect: 'follow',
  }

  return fetch(lambdaEndpoint, requestOptions)
    .then((response) => {
      let data = {
        result: response.status === 200 ? 'success' : 'error',
      }
      return data
    })
    .catch((error) => {
      console.log(error)
      let data = {
        result: 'error',
      }
      return data
    })
}
