/**
 * Format all general data
 */
import { getGameData } from './game-data'
import { getRatingData } from './rating-data'

export function getAllData(data) {
  const gameData = getGameData(data)
  const ratingsData = getRatingData(data)
  const brandData = data.brand ? data.brand.edges[0].node : null
  const globalData = data.global ? data.global.edges[0].node : null
  const navData = data.navigation ? data.navigation.edges[0].node : null
  const seoData = data.seo ? data.seo.edges[0].node : null
  const stringsData = data.strings ? data.strings.edges[0].node : null
  const directoriesData = data.pageDirectories ? data.pageDirectories.edges : null

  const generalData = { gameData, ratingsData, brandData, globalData, navData, seoData, stringsData, directoriesData }
  return generalData
}