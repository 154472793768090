import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { localizePath } from '@base/helpers/localization/localize'
import Image from '@base/parts/image'
import Link from '@base/parts/link'
import SocialLinks from '@base/elements/social-links'
import Copyrights from '@base/elements/copyrights'
import Ratings from '@base/elements/ratings'
import Stores from '@base/elements/store/store-buttons'

import FooterNav from '@elements/nav-footer'

const Footer = ({ pageContext, theme, data }) => {
  const ref = useRef(null)
  const [refLoad, inViewLoad] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  if (!data) {
    return ''
  }

  const footerNav = data.footerNavData
  const themeModifier = data.themeModifier ? data.themeModifier : 'none'
  const brand = data.brandData
  const footer = data.footerData
  const socialLinks = data.socialData
  const gameData = data.gameData
  const ratingsData = data.ratingsData && data.ratingsData.ratings ? data.ratingsData.ratings : null
  const additionnalLogo = footer.additionnalLogo ? footer.additionnalLogo : null

  return (
    <footer ref={ref} className="footer relative z-1 box-border text-base">
      <div
        ref={refLoad}
        className="footer-container relative z-1 overflow-hidden"
      >
        <div className="container flex flex-col items-center pt-24 lg:pt-36 text-white">
          {!themeModifier.hideMainNav ? (
            <Link to={localizePath({ locale: pageContext.locale, slug: '' })}>
              {brand.alternateLogo ? (
                <Image
                  media={brand.alternateLogo}
                  className="w-72 h-32 lg:w-[35rem] lg:h-auto"
                />
              ) : (
                <Image media={brand.fullLogo} className="w-72 h-32 lg:w-[35rem] lg:h-auto" />
              )}
            </Link>
          ) : brand.alternateLogo ? (
            <Image media={brand.alternateLogo} className="w-72 h-32 lg:w-[35rem] lg:h-auto" />
          ) : (
            <Image media={brand.fullLogo} className="w-72 h-32 lg:w-[35rem] lg:h-auto" />
          )}
        </div>

        {/* BUY GAME */}
        {gameData && gameData.stores !== null && (
          <div className="container mb-12 flex flex-col items-center justify-center text-white lg:mb-24">
            {footer && footer.storeTitle && (
              <h3 className="mt-8 lg:mt-12 mb-6 lg:mb-8 text-center font-sans uppercase tracking-widest text-md lg:text-xl">
                {footer.storeTitle}
              </h3>
            )}
            <Stores
              pageContext={pageContext}
              layout="icons"
              product="base-game"
              gameData={gameData}
              storeClassName="text-white hover:text-gold"
              className="m-0 flex flex-row items-center space-x-8"
              eventAction={'platform_icon'}
              eventSection={'footer'}
            />
          </div>
        )}

        {/* SOCIAL */}
        {socialLinks && socialLinks !== null && (
          <>
            <div className="container mb-16 lg:mb-24 flex flex-col items-center text-white">
              <div className="separator-primary relative mb-12 w-full lg:mb-24">
                <div className="h-[1px] lg:h-[2px] w-full bg-primary"></div>
                <i className="left"></i>
                <i className="right"></i>
              </div>
              <h3 className="mb-4 lg:mb-12 text-center font-sans uppercase tracking-widest text-md lg:text-xl">
                {footer.socialTitle}
              </h3>
              <div>
                <SocialLinks
                  social={socialLinks}
                  className="flex gap-x-8"
                  iconClassName="social-links-item-round"
                />
              </div>
            </div>
          </>
        )}

        {/* RATING */}
        {inViewLoad && ratingsData !== null && (
          <div className="container mb-24 flex flex-col items-center border-t-2 text-white">
            <div className="separator-primary relative mb-12 w-full lg:mb-24">
              <div className="h-[1px] lg:h-[2px] w-full bg-primary"></div>
              <i className="left"></i>
              <i className="right"></i>
            </div>
            <Ratings />
          </div>
        )}

        <div className="footer-bg pointer-events-none absolute left-0 top-0 -z-1 h-full w-full bg-no-repeat hover:text-secondary" />
      </div>

      {/* FOOTER NAV */}
      <div
        className={`relative flex flex-col justify-center py-6 lg:py-12 text-center lg:text-left
          ${theme &&
          theme.FooterNav &&
          `
            ${theme.FooterNav.bg
            ? `bg-${theme.FooterNav.bg}`
            : 'bg-secondary'
          }
            ${theme.FooterNav.text &&
          `
              ${theme.FooterNav.text.default
            ? `text-${theme.FooterNav.text.default}`
            : 'text-white'
          }`
          }`
          }`}
      >
        <div className="container relative z-[20] lg:px-0">
          {footerNav && footerNav !== null && (
            <FooterNav
              links={footerNav}
              logo={additionnalLogo}
              theme={theme.FooterNav}
            />
          )}
          <Copyrights
            className="text-center text-[11px] leading-normal tracking-wider"
            text={footer.legalText}
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer
