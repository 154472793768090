/**
 * Format all pageContext data
 */
import { getThemeData } from './theme-data'

export function getContextData(context, data) {
  const id = context.id
  const dataLayer = context.dataLayer
  const defaultLocale = context.defaultLocale
  const locale = context.locale
  const locales = context.locales
  const postType = context.postType
  const title = data.pageData ? (data.pageData.title ? data.pageData.title : (data.pageData.shortName ? data.pageData.shortName : null)) : null
  const slug = context.slug
  const strapiId = context.strapiId
  const localizations = data.pageData ? data.pageData.localizations : []
  const theme = getThemeData(context.siteTheme, context.globalData.navigation.edges[0].node)

  // Get page's default title (English title for tracking uniformity)
  let defaultLocaleTitle = title
  if (data.pageData && data.pageData.localizations && data.pageData.localizations.data && data.pageData.localizations.data.length > 0) {
    const defaultLocalization = data.pageData.localizations.data.find((e) => e.attributes.locale === defaultLocale)
    defaultLocaleTitle = defaultLocalization && defaultLocalization.attributes ? defaultLocalization.attributes.title : title
  }

  // Generate a cleaner pageContext
  const contextData = { id, dataLayer, defaultLocale, defaultLocaleTitle, locale, locales, localizations, postType, theme, title, slug, strapiId }
  return contextData
}