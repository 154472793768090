import React, { useState, useEffect } from 'react'

import { dateFormat } from '@base/utils/helpers/date-time'
import { localizePath } from '@base/helpers/localization/localize'
import LocaleSwitch from '@base/elements/locale-switch'
import NavUtilsNew from '@base/elements/nav/nav-utils-new'
import Image from '@base/parts/image'
import Link from '@base/parts/link'
import Button from '@base/parts/button'
import PromoTag from '@base/elements/store/promo-tag'

import MainNav from '@elements/nav-main'
import arrow from '@img/icons/arrow.svg'
import localeIcon from '@img/icons/locale-selector.svg'

const Header = ({ pageContext, data, theme, children, className }) => {
  const [scrolled, setScrolled] = useState(false)
  let now = new Date()
  let currentDate = dateFormat(now)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  if (!data) {
    return ''
  }

  const themeModifier = data.themeModifier ? data.themeModifier : 'none'
  const mainNav = data.mainNavData
  const ctaLinks = data.ctaData ? data.ctaData : null
  const brand = data.brandData

  // Stick main nav to top on scroll
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset >= 250) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  return (
    <header
      className={`header-main header-${pageContext.locale
        } pointer-events-none sticky top-0 z-god transition-all ${className ? className : ''
        } ${scrolled ? 'scrolled' : 'initial'}`}
    >
      {!themeModifier.hideMainNav && (
        <NavUtilsNew
          locale={pageContext.locale}
          project="ioi"
          className="pointer-events-auto relative flex h-16 flex-row items-center justify-end bg-black px-5 py-2 lg:h-[55px] lg:px-8 lg:py-4"
        />
      )}
      <div
        className={`nav pointer-events-auto relative flex h-20 justify-between pl-10 lg:h-32 lg:pl-16 lg:pr-0 ${scrolled && !themeModifier.hideMainNav ? 'scrolled' : 'initial'
          } ${!themeModifier.hideMainNav ? 'pr-[48px]' : ''}`}
      >
        <div className="relative z-10 flex w-full items-center justify-start lg:w-auto">
          {!themeModifier.hideMainNav ? (
            <Link
              to={localizePath({ ...pageContext, slug: '' })}
              className="inline-flex drop-shadow-none transition-all duration-300 hover:drop-shadow-2xl"
            >
              <Image
                media={brand.fullLogo}
                className={`h-auto w-[74px] text-white lg:w-40 xl:w-52 ${scrolled ? 'scrolled' : 'initial'
                  }`}
              />
            </Link>
          ) : (
            <div className="inline-flex">
              <Image
                media={brand.fullLogo}
                className={`h-auto w-[74px] text-white lg:w-64 ${scrolled ? 'scrolled' : 'initial'
                  }`}
              />
            </div>
          )}
        </div>

        {!themeModifier.hideMainNav && (
          <MainNav
            links={mainNav}
            brand={brand}
            theme={theme && theme.MainNav !== null ? theme.MainNav : null}
            disableAutoActive={true}
            pageContext={pageContext}
            className="absolute left-0 z-1 h-full w-full list-none justify-between border-0 text-center font-sans text-base text-base tracking-wider transition-all lg:relative lg:ml-16 lg:flex lg:flex-row lg:items-center lg:text-sm xxl:text-base xxxl:ml-28"
          >
            <div className="flex lg:hidden">
              {pageContext.localizations && (
                <LocaleSwitch
                  className="py-8 pl-20 pr-12 font-nova text-base tracking-wider text-white lg:font-display lg:text-md"
                  pageContext={pageContext}
                  selectorIcon={arrow}
                  languageIcon={localeIcon}
                />
              )}
            </div>
            <div className="sticky-top-mobile sticky flex flex-col items-center space-y-8 overflow-hidden p-10 pt-20 lg:hidden">
              {ctaLinks !== null &&
                ctaLinks.map((ctaLink, i) => (
                  <Button
                    key={i}
                    id={ctaLink.buttonId}
                    link={ctaLink.link}
                    trigger={ctaLink.trigger}
                    size="large"
                    style="header"
                    pageContext={pageContext}
                    gsEvent={
                      ctaLink?.eventAction === 'wishlist'
                        ? 'wishlist_click'
                        : ctaLink?.eventAction === 'buy'
                          ? 'buy_click'
                          : ''
                    }
                    event={ctaLink.eventAction ? ctaLink.eventAction : ''}
                    eventSection={
                      ctaLink.eventElement ? ctaLink.eventElement : 'nav'
                    }
                    vendor={'steam store'}
                    modalContent={
                      ctaLink.trigger === 'buyModal'
                        ? {
                          logo: brand.fullLogo,
                        }
                        : ''
                    }
                  >
                    <div className="btn-content">
                      <i className="btn-decorator btn-decorator-left" />
                      <span>
                        {data?.gameData?.promos?.discounts?.map(
                          (promo) =>
                            ctaLink.link.includes(promo.target) && (
                              <PromoTag
                                data={[
                                  {
                                    ...promo,
                                    startDate: promo.dateFrom,
                                    endDate: promo.dateTo,
                                  },
                                ]}
                              />
                            )
                        )}
                        {ctaLink.title}
                        {ctaLink.icon && (
                          <Image
                            alt={ctaLink.icon.alternativeText}
                            media={ctaLink.icon}
                            className={`ml-4 h-auto w-7 shrink-0`}
                          />
                        )}
                      </span>
                      <i className="btn-decorator btn-decorator-right" />
                    </div>
                  </Button>
                ))}
            </div>
          </MainNav>
        )}

        <div className="flex h-full w-auto flex-row items-center justify-end">
          {pageContext.localizations &&
            pageContext.localizations?.data?.length > 0 && (
              <li
                key="mainnav-language"
                className="nav-item relative z-1 hidden h-full flex-col items-center justify-between px-8 text-md text-white lg:flex"
              >
                <LocaleSwitch
                  className="hidden text-primary lg:flex"
                  pageContext={pageContext}
                  selectorIcon={arrow}
                  languageIcon={localeIcon}
                />
              </li>
            )}
          {ctaLinks !== null &&
            ctaLinks.map(
              (ctaLink, i) =>
                !ctaLink.mobileOnly && (
                  <div className="main-cta lg:px-8">
                    <Button
                      key={i}
                      id={ctaLink.buttonId}
                      link={ctaLink.link}
                      trigger={ctaLink.trigger}
                      size="large"
                      style="header"
                      pageContext={pageContext}
                      gsEvent={
                        ctaLink?.eventAction === 'wishlist'
                          ? 'wishlist_click'
                          : ctaLink?.eventAction === 'buy'
                            ? 'buy_click'
                            : ''
                      }
                      event={ctaLink.eventAction ? ctaLink.eventAction : ''}
                      eventSection={
                        ctaLink.eventElement ? ctaLink.eventElement : 'nav'
                      }
                      vendor={'steam store'}
                      modalContent={
                        ctaLink.trigger === 'buyModal'
                          ? {
                            logo: brand.fullLogo,
                          }
                          : ''
                      }
                    >
                      <div className="btn-content">
                        <i className="btn-decorator btn-decorator-left" />
                        <span
                          className={data?.gameData?.promos?.discounts?.map(
                            (promo) =>
                              ctaLink.link.includes(promo.target) &&
                              currentDate >= dateFormat(promo.dateFrom) &&
                              currentDate < dateFormat(promo.dateTo) &&
                              'button-promo'
                          )}
                        >
                          {data?.gameData?.promos?.discounts?.map(
                            (promo) =>
                              ctaLink.link.includes(promo.target) && (
                                <PromoTag
                                  data={[
                                    {
                                      ...promo,
                                      startDate: promo.dateFrom,
                                      endDate: promo.dateTo,
                                    },
                                  ]}
                                />
                              )
                          )}
                          {ctaLink.title}
                          {ctaLink.icon && (
                            <Image
                              alt={ctaLink.icon.alternativeText}
                              media={ctaLink.icon}
                              className={`ml-4 h-auto w-7 shrink-0`}
                            />
                          )}
                        </span>
                        <i className="btn-decorator btn-decorator-right" />
                      </div>
                    </Button>
                  </div>
                )
            )}
        </div>
      </div>
      {/* DYNAMIC ZONE - For displaying custom content in header */}
      {children && children !== null && children}
    </header>
  )
}

export default Header
