import React from 'react'
import { CookiesProvider } from 'react-cookie'

import PageContextProvider from '@base/utils/contexts/page-context'
import CountryLegalSettings from '@base/utils/helpers/geolocalization/country-legal-settings'
import FrameworkTools from '@base/utils/dev/dev-tools'
import AgeGate from '@base/elements/age-gate/age-gate'
import DynamicSections from '@base/layout/dynamic'

import Header from './header'
import Footer from './footer'
import NewsletterBackground from '../elements/newsletter-background'
import NotificationBanner from '../elements/notification-banner'

const Layout = props => {
  const pageContext = props.pageContext
  const dataLayer = props.dataLayer
  const theme = pageContext.theme ? pageContext.theme.siteTheme : ''
  const globalData = props.globalData
  const pageData = props.data.pageData
  const staticData = pageContext.content
  const localizations = pageContext.localizations ? pageContext.localizations : []
  const { locale, slug, defaultLocale, defaultLocaleTitle, pageTitle, postType } = pageContext

  // Site Custom theme
  const headerStyles = theme && theme.header ? theme.header : undefined
  const footerStyles = theme && theme.footer ? theme.footer : undefined
  const newsletterStyles = theme && theme.content && theme.content.Newsletter ? theme.content.Newsletter : undefined
  const buyGameStyles = theme && theme.content && theme.content.BuyGame ? theme.content.BuyGame : undefined
  const themeModifier = props.data.themeModifier

  // General site data
  const brandData = globalData.brandData
  const seoData = globalData.seoData && globalData.seoData.metadata
  const gameData = globalData.gameData
  const socialData = globalData.globalData && globalData.globalData.socialLinks
  const ratingsData = globalData.ratingsData && globalData.ratingsData
  const ageGateData = globalData.ageGateData

  // Localized Strings Data
  const stringsData = globalData.stringsData
  const pageDirectories = globalData.directoriesData
  const stringsErrors = stringsData && stringsData.errors ? stringsData.errors : ''
  const stringsDates = stringsData && stringsData.dates ? stringsData.dates : ''

  // Header specific data
  const mainNavData = globalData.navData && globalData.navData.mainNav
  const ctaData = globalData.navData && globalData.navData.ctaLinks

  // Dynamic sections specific data
  const newsletterData = globalData?.globalData?.newsletterForm
  const disableBuyGameSection = pageData?.disableBuyGameSection ? pageData.disableBuyGameSection : (staticData && staticData.disableBuyGameSection) ? staticData.disableBuyGameSection : pageContext.postType === 'article' ? true : false
  const disableNewsletterSignup = pageData?.disableNewsletterSignup ? pageData.disableNewsletterSignup : (staticData && staticData.disableNewsletterSignup) ? staticData.disableNewsletterSignup : false
  const bannerData = globalData?.globalData?.notificationBanner
  const contentSections = props.data?.blocksData?.contentSections
  const formSection = contentSections?.find(x => x.strapi_component === 'sections.form-block')
  const disableNotificationBanner = bannerData?.enabled && !formSection ? false : true

  const Newsletter = () => {
    return <NewsletterBackground />
  }
  const BuyGame = () => {
    return ''
  }

  // Footer specific data
  const footerNavData = globalData.navData && globalData.navData.footerNav
  const footerData = globalData.globalData && globalData.globalData.footer

  return (
    <CookiesProvider>
      <PageContextProvider>
        <CountryLegalSettings
          data={ratingsData}
        />
        <Header
          data={{ brandData, mainNavData, ctaData, socialData, seoData, themeModifier, gameData }}
          theme={headerStyles}
          pageContext={{ ...pageContext, pageDirectories }}
          globalContext={{ locale, slug, localizations, defaultLocale, defaultLocaleTitle, pageDirectories, gameData, pageTitle, postType, dataLayer }}
          themeModifier={{
            hideMainNav: themeModifier ? themeModifier.hideMainNav : false,
            storeCTALabel: themeModifier ? themeModifier.storeCTALabel : null,
            customTheme: themeModifier ? themeModifier.customTheme : false,
            customNavTitle: themeModifier ? themeModifier.customNavTitle : false,
          }}
        >
          {!disableNotificationBanner && (
            <NotificationBanner
              data={bannerData}
              pageContext={pageContext}
              globalData={globalData}
              className={`notification-banner-${bannerData.type} fixed right-0 z-40`}
            />
          )}
        </Header>
        <div className="content-wrapper">
          {props.children}
        </div>
        <DynamicSections
          data={{ gameData, newsletterData, stringsData, disableBuyGameSection, disableNewsletterSignup }}
          globalContext={{ locale, slug, localizations, postType, defaultLocaleTitle, brandData, dataLayer }}
          styling={{ newsletterStyles, buyGameStyles }}
          newsletter={Newsletter}
          newsletterList="islandsofinsight"
          buygame={BuyGame}
        />
        <Footer
          data={{ footerNavData, footerData, brandData, socialData, gameData, ratingsData }}
          theme={footerStyles}
          pageContext={pageContext}
          customThemeOptions={{
            removeFooterSocial: themeModifier ? themeModifier.removeFooterSocial : false,
            removeFooterStoreLinks: themeModifier ? themeModifier.removeFooterStoreLinks : false,
            customTheme: themeModifier ? themeModifier.customTheme : false
          }}
        />

        {(ageGateData && ageGateData.siteEnabled) && (
          <AgeGate
            data={ageGateData}
            strings={{ stringsErrors, stringsDates }}
            dateFormat={locale === 'ja' ? 'yyyy-mm-dd' : 'dd-mm-yyyy'}
            classes={{
              modal: `fixed top-0 z-god flex h-screen w-screen justify-center sm:items-center bg-black`,
              content: `absolute z-20 box-border flex w-full flex-col p-8 pt-20 text-white sm:h-auto sm:w-auto sm:justify-center sm:pt-8`,
              form: ``,
              input: `age-gate-input`,
              submit: `button button-primary-color button-narrow disabled h-20 w-full sm:w-1/4`,
              error: `absolute top-full left-0 z-under flex w-full flex-col items-center mt-12`
            }}
          />
        )}
      </PageContextProvider>
      <FrameworkTools />
    </CookiesProvider>
  )
}

export default Layout
