import React from 'react'
import { navigate } from 'gatsby'
import trackLink from '@base/utils/helpers/tracking/track-link'

const AnchorLink = (props) => {
  const handleClick = (event) => {
    event.preventDefault()

    if (
      props.trackClick &&
      (props.trackClick.eventAction || props.trackClick.event)
    ) {
      trackLink(props.trackClick)
    }
    let anchor = document.getElementById(props.to)

    if (anchor && anchor !== null) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      if (location.pathname.split('/')[1] === 'fr') {
        return navigate('/fr')
      }
      return navigate('/')
    }
  }

  return (
    <a
      onClick={handleClick}
      aria-label={`Scroll to "${props.children}" Section`}
      data-link={props.to}
      className={props.className}
    >
      {props.children}
    </a>
  )
}

export default AnchorLink
