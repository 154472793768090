import React from 'react'

import Nav from '@base/elements/nav/nav'
import NavItem from '@base/elements/nav/nav-item'
import NavSub from '@base/elements/nav/nav-sub'
import Image from '@base/parts/image'
import Button from '@base/parts/button'
import external from '@base/assets/icons/external-link.svg'
import subnav from '@base/assets/icons/sub-menu.svg'

import AnchorLink from './anchor-link'

const MainNav = (props) => {
  const theme = props.theme
  const mobileNav =
    theme && theme !== null && theme.mobileNav ? theme.mobileNav : 'hamburger'

  return (
    <Nav
      name="Primary Navigation"
      className={`${props.className ? props.className : ''} ${
        props.links?.length <= 1 ? 'single' : 'multi'
      }`}
      mobileNav={mobileNav}
      scrollLock={props.scrollLock}
      disableAutoActive={
        props.disableAutoActive ? props.disableAutoActive : false
      }
    >
      {props.links &&
        props.links.map((navLink) => (
          <>
            {navLink.anchor ? (
              <AnchorLink
                to={navLink.url}
                ariaLabel={`Scroll to ${
                  navLink.text ? navLink.text : navLink.to
                }`}
                dataLink={navLink.url}
                trackClick={{
                  event: navLink?.eventAction,
                  eventSection: 'nav',
                  pageContext: props.pageContext,
                }}
                className={`
                  nav-item hidden w-full shrink-0 cursor-pointer flex-col items-center font-bold uppercase lg:flex lg:w-auto
                  ${
                    theme &&
                    theme.text &&
                    `
                    ${
                      theme.text.default
                        ? `text-${theme.text.default}`
                        : 'text-white'
                    } 
                    ${
                      theme.text.hover
                        ? `hover:text-${theme.text.hover}`
                        : 'hover:text-primary'
                    }
                    ${
                      theme.text.active
                        ? `active:text-${theme.text.active}`
                        : 'active:text-primary'
                    }
                  `
                  }
                `}
              >
                {navLink.text}
              </AnchorLink>
            ) : (
              <NavItem
                title={navLink.text}
                trigger={navLink.subNavTrigger ? navLink.subNavTrigger : 'none'}
                key={`mainnav-link-${navLink.id ? navLink.id : '0'}`}
                target={navLink.newTab ? '_blank' : ''}
                href={
                  navLink.url && navLink.url !== null
                    ? `${navLink.url}${
                        navLink.anchor ? `#${navLink.anchor}` : ''
                      }`
                    : ''
                }
                anchor={
                  navLink.anchor && navLink.anchor !== null
                    ? navLink.anchor
                    : null
                }
                linkClasses={`main-link inline-flex items-center justify-between lg:justify-center fill-current w-full uppercase `}
                subNavIndicator={subnav}
                disableAutoActive={
                  props.disableAutoActive ? props.disableAutoActive : false
                }
                className={`nav-item flex w-full shrink-0 cursor-default flex-col items-center font-bold lg:w-auto
                ${
                  theme &&
                  theme.text &&
                  `
                  ${
                    theme.text.default
                      ? `text-${theme.text.default}`
                      : 'text-white'
                  } 
                  ${
                    theme.text.hover
                      ? `hover:text-${theme.text.hover}`
                      : 'hover:text-primary'
                  }
                  ${
                    theme.text.active
                      ? `active:text-${theme.text.active}`
                      : 'active:text-primary'
                  }
                `
                }
              `}
                pageContext={props.pageContext}
                eventCategory={navLink?.eventCategory}
                eventAction={navLink?.eventAction}
                eventLabel={navLink?.eventLabel}
                eventElement={navLink?.eventElement}
                eventType={
                  navLink.eventCategory === 'interaction'
                    ? navLink.newTab
                      ? 'externalLink'
                      : 'internalLink'
                    : 'main'
                }
                eventSection={navLink?.eventSection}
                eventPage={props.eventPage}
              >
                <NavSub className="nav-sub left-0 top-full flex h-0 w-full flex-col justify-center overflow-hidden transition-all duration-500 lg:container lg:fixed lg:left-1/2 lg:-translate-x-1/2 lg:flex-row lg:space-x-20 lg:bg-transparent">
                  {navLink.subNav &&
                    navLink.subNav.map((subLink) => (
                      <NavItem
                        title={subLink.text}
                        key={'mainnav-link-' + subLink.id}
                        target={subLink.newTab ? '_blank' : ''}
                        href={
                          subLink.url && subLink.url !== null
                            ? `${subLink.url}${
                                subLink.anchor ? `#${subLink.anchor}` : ''
                              }`
                            : subLink.anchor
                            ? `#${subLink.anchor}`
                            : '#'
                        }
                        linkClasses="main-link inline-flex items-center p-0 justify-start lg:justify-center fill-current w-full uppercase"
                        subNavIndicator={subnav}
                        externalIndicator={external}
                        className={`nav-item flex w-full cursor-default flex-col items-center border-b-2 border-grayDarker font-bold lg:w-auto lg:border-b-0
                      ${
                        theme &&
                        theme.text &&
                        `
                        ${
                          theme.text.default
                            ? `text-${theme.text.default}`
                            : 'text-white'
                        } 
                        ${
                          theme.text.hover
                            ? `hover:text-${theme.text.hover}`
                            : 'hover:text-primary'
                        }
                        ${
                          theme.text.active
                            ? `active:text-${theme.text.active}`
                            : 'active:text-primary'
                        }
                      `
                      }
                    `}
                        pageContext={props.pageContext}
                        eventCategory={
                          subLink.eventCategory ? subLink.eventCategory : ''
                        }
                        eventAction={
                          subLink.eventAction ? subLink.eventAction : ''
                        }
                        eventLabel={
                          subLink.eventLabel ? subLink.eventLabel : ''
                        }
                        eventElement={
                          subLink.eventElement ? subLink.eventElement : ''
                        }
                        eventType={
                          subLink.eventCategory === 'interaction'
                            ? subLink.newTab
                              ? 'externalLink'
                              : 'internalLink'
                            : 'main'
                        }
                      >
                        <>
                          {subLink.thumbnailImage && (
                            <Image
                              media={subLink.thumbnailImage}
                              className="nav-panel-image w-full object-cover"
                            />
                          )}
                          {subLink.buttonLabel && (
                            <div className="button-wrapper gatsby-hidden-mobile relative w-full">
                              <Button
                                className={`button button-outline button-small inline-block justify-center hover:bg-transparent ${
                                  subLink.newTab ? 'button-external' : ''
                                }`}
                                overwrite
                                trigger={subLink.newTab ? 'externalLink' : ''}
                              >
                                {subLink.buttonLabel}
                              </Button>
                            </div>
                          )}
                        </>
                      </NavItem>
                    ))}
                </NavSub>
              </NavItem>
            )}
          </>
        ))}
      {props.children}
    </Nav>
  )
}

export default MainNav
