/**
 * Validate a subscription form before sending it
 */

export const validateSubscription = (fieldsMap, errorMessages) => {
  // H0n€YP0t (called "someField") - Prevent b0tz from submitting the form
  const someField = fieldsMap.someField
  if (someField.value !== '') {
    validationMessage(fieldsMap, errorMessages, false, 'error', 'generic')
    return false
  } else {
    validationMessage(fieldsMap, errorMessages, true)
  }

  // Form Validation
  const emailField = fieldsMap.emailField
  if (!emailField.value) {
    validationMessage(fieldsMap, errorMessages, false, 'error', 'email')
  }
  if (emailField.value !== '' && emailField.checkValidity()) {
    validationMessage(fieldsMap, errorMessages, true)
  } else {
    validationMessage(fieldsMap, errorMessages, false, 'error', 'email')
    return false
  }

  const wishlistField = fieldsMap.wishlistField
  if (wishlistField.checked) {
    validationMessage(fieldsMap, errorMessages, true)
  } else {
    validationMessage(fieldsMap, errorMessages, false, 'error', 'consent')
    return false
  }

  const legalField = fieldsMap.legalField
  if (legalField.checked) {
    validationMessage(fieldsMap, errorMessages, true)
  } else {
    validationMessage(fieldsMap, errorMessages, false, 'error', 'consent')
    return false
  }

  const consentField = fieldsMap.consentField
  if (consentField.checked) {
    validationMessage(fieldsMap, errorMessages, true)
  } else {
    validationMessage(fieldsMap, errorMessages, false, 'error', 'consent')
    return false
  }

  return true
}

// Display Error/Success
export const validationMessage = (
  fieldsMap,
  errorMessages,
  result,
  type,
  message
) => {
  const form = fieldsMap.form

  // Set the validation messages
  const error = fieldsMap.newsletterErrorField
  const errorMessage = fieldsMap.newsletterErrorFieldMessage
  let newsletterErrorMessage = ''
  if (message === 'generic' || message === 'error') {
    newsletterErrorMessage =
      errorMessages.newsletterGenericError &&
      errorMessages.newsletterGenericError !== null
        ? errorMessages.newsletterGenericError
        : 'An error occured. Please try again.'
  } else if (message === 'email') {
    newsletterErrorMessage =
      errorMessages.newsletterEmailError &&
      errorMessages.newsletterEmailError !== null
        ? errorMessages.newsletterEmailError
        : 'Your email address is invalid.'
  } else if (message === 'consent') {
    newsletterErrorMessage =
      errorMessages.newsletterConsentError &&
      errorMessages.newsletterConsentError !== null
        ? errorMessages.newsletterConsentError
        : 'You must accept to receive newsletters, information, and promotions.'
  } else if (message === 'success') {
    newsletterErrorMessage =
      errorMessages.newsletterSuccess &&
      errorMessages.newsletterSuccess !== null
        ? errorMessages.newsletterSuccess
        : 'Thank you for subscribing!'
  } else {
    newsletterErrorMessage =
      message && message !== null ? message : 'No error message to display.'
  }

  error.classList.remove('success')
  error.classList.remove('error')

  if (result === false) {
    if (type === 'error') {
      error.classList.add('error')
    } else if (type === 'success') {
      error.classList.add('success')
      form.reset()
    } else {
      newsletterErrorMessage =
        errorMessages.newsletterGenericError &&
        errorMessages.newsletterGenericError !== null
          ? errorMessages.newsletterGenericError
          : 'An error occured. Please try again.'
    }
    errorMessage.innerHTML = newsletterErrorMessage
    error.classList.add('newsletter-message-display')
    setTimeout(function () {
      error.classList.remove('newsletter-message-display')
    }, 4000)
  } else {
    error.classList.remove('newsletter-message-display')
  }
}
