import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '@bhvr/web-design-system'
import debounce from 'lodash.debounce'
import { useCookies } from 'react-cookie'

import { PageContext } from '@base/utils/contexts/page-context'
import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context'
import triggerEventClick from '@base/utils/helpers/tracking/trigger-event-click'
import trackQuery from '@base/utils/helpers/tracking/track-query'
import useModalBox from '@base/utils/hooks/useModalBox'
import Markdown from '@base/parts/markdown'
import Heading from '@base/parts/heading'
import Image from '@base/parts/image'

import { iterableHandler } from '../../utils/helpers/handler-iterable'
import {
  validateSubscription,
  validationMessage,
} from '../../utils/helpers/form-validation'

/**
 * Display the Early Access form (for Iterable)
 */
const EarlyAccessForm = (props) => {
  const {
    pageContext,
    data,
    strings,
    type,
    styling,
    dataLayer,
    leadTracking,
    id,
    list,
  } = props
  const randomID = Math.floor(Math.random() * 100) + Date.now()
  const dataID = id ? id : randomID
  const form = useRef(null)
  const someField = useRef(null)
  const emailField = useRef(null)
  const consentWrapper = useRef(null)
  const consentField = useRef(null)
  const legalField = useRef(null)
  const wishlistField = useRef(null)
  const newsletterErrorField = useRef(null)
  const newsletterErrorFieldMessage = useRef(null)
  const [completeForm, setCompleteForm] = useState(false)
  const [newsletterFormValid, setNewsletterFormValid] = useState(null)
  const [cookies, setCookie] = useCookies()

  const newsletterData = data.newsletterData
  const errorMessages = strings?.errors
    ? strings.errors
    : strings?.error
    ? strings.error
    : ''
  const activeHandler = 'iterable'

  // Modal box for Age gating
  const { modalState } = useContext(PageContext)
  const [modalOpen, setModalOpen] = modalState
  const { ageGate, currentCountryCode, legalAge, formSubmit } = useContext(
    GeolocalizationContext
  )
  const [ageGateSettings, setAgeGateSettings] = ageGate
  const [countryCode, setCountryCode] = currentCountryCode
  const [isLegalAge, setLegalAge] = legalAge
  const [formSubmitted, setFormSubmitted] = formSubmit
  const [modalBox, openModalBox] = useModalBox()

  const setModalBoxContent = (type, className, data) => {
    setAgeGateSettings((prev) => ({ ...prev, displayed: true, usage: dataID }))
    openModalBox(type, className, data)
  }

  // Style the form with CSS classes
  const formType = type ? type : 'full'
  const styles = {
    form: styling.form ? styling.form : 'relative',
    title: styling.title ? styling.title : '',
    subtitle: styling.subtitle ? styling.subtitle : '',
    description: styling.description
      ? styling.description
      : 'mb-10 text-sm lg:text-md',
    fieldsWrapper: styling.fieldsWrapper
      ? styling.fieldsWrapper
      : 'mb-6 flex flex-col lg:flex-row sm:items-end',
    emailFieldSet: styling.emailFieldSet
      ? styling.emailFieldSet
      : 'flex w-full lg:w-1/2 flex-col mb-6 lg:mb-0',
    emailLabel: styling.emailLabel
      ? styling.emailLabel
      : 'text-bold mb-4 pl-2 text-base leading-none tracking-wide',
    emailField: styling.emailField
      ? styling.emailField
      : 'h-20 border-2 border-primary bg-transparent px-12 py-4 text-white text-base rounded-none',
    submitButton: styling.submitButton
      ? styling.submitButton
      : 'button button-secondary-color button-narrow h-20 w-full lg:w-1/2 z-10',
    errors: styling.errors
      ? styling.errors
      : 'relative px-4 z-10 flex items-center pointer-events-none',
    consentField: styling.consentField
      ? styling.consentField
      : 'mt-6 mb-12 flex border border-white px-12 py-6',
    terms: styling.terms
      ? styling.terms
      : 'bg-grayMedium/20 px-12 py-8 text-sm tracking-wide',
  }

  // Data for tracking for sent
  let formDataLayer = {
    pageContext: pageContext,
    eventElement: `${
      dataLayer?.eventCategory ? dataLayer?.eventCategory : 'form'
    } ${dataLayer?.eventLocation ? dataLayer?.eventLocation : 'footer'}`,
    eventCategory: dataLayer?.eventCategory ? dataLayer.eventCategory : 'form',
    eventAction: dataLayer?.eventAction
      ? dataLayer.eventAction
      : 'newsletter subscription',
    eventType: dataLayer?.eventType ? dataLayer.eventType : 'form',
    eventLocation: dataLayer?.eventLocation
      ? dataLayer.eventLocation
      : 'footer',
  }

  // GA4 Tracking
  if (pageContext?.dataLayer?.config === 'GA4') {
    formDataLayer = {
      pageContext: pageContext,
      event: dataLayer?.event ? dataLayer.event : 'newsletter_subscription',
      page: dataLayer?.page ? dataLayer.page : '',
      section: dataLayer?.section ? dataLayer.section : 'footer',
    }
  }

  // Track Leads provenance
  let lead = {
    medium: 'direct',
    result: null,
  }
  if (leadTracking && leadTracking !== null) {
    lead = trackQuery(leadTracking)
  }

  // Error & Success messages
  let newsletterErrorMessage = ''
  let fieldsMap = []
  useEffect(() => {
    fieldsMap = {
      form: form.current,
      someField: someField.current,
      emailField: emailField.current,
      consentField: consentField.current,
      legalField: legalField.current,
      wishlistField: wishlistField.current,
      newsletterErrorField: newsletterErrorField.current,
      newsletterErrorFieldMessage: newsletterErrorFieldMessage.current,
    }
  })

  // Display Consent fields when user fills the email field
  const debouncedChangeHandler = (time) => debounce(changeHandler, time)
  const changeHandler = (e) => {
    if (e.target.value.length > 0) {
      consentWrapper.current.classList.add('active')
    } else {
      consentWrapper.current.classList.remove('active')
    }
  }

  // Submit the pre-validated form data to the mailing list handler (Iterable)
  async function submitToHandler(formData) {
    //setModalOpen(false)

    if (activeHandler && activeHandler !== null) {
      new Promise((resolve) => {
        let data = ''
        const currentLocale = pageContext.locale

        // Select which handler to use depending of the available variables (if both available, Iterable will prevail)
        if (activeHandler === 'iterable') {
          data = iterableHandler(
            formData,
            currentLocale,
            countryCode,
            lead,
            list
          )
        }
        resolve(data)
      }).then(function (data) {
        const message = data.msg ? data.msg : data.result

        // Display validation message and clean form if successfull
        validationMessage(fieldsMap, errorMessages, false, data.result, message)
        setNewsletterFormValid(false)

        if (data.result === 'success') {
          // Send tracking data to Analytics
          triggerEventClick(formDataLayer)

          // Send a GameSight Event on form submit
          if (
            process.env.GATSBY_GAMESIGHT_ID &&
            dataLayer?.gsEvent &&
            typeof tbhvr !== 'undefined' &&
            typeof tbhvr === 'function'
          ) {
            tbhvr('send', dataLayer?.gsEvent)
          }
        }
      })
    } else {
      alert(
        'No subscription handler available. Please define one or make sure every required variables are defined.'
      )
      return false
    }
  }

  const submitForm = (e) => {
    e.preventDefault()

    // Validate Form here
    const validForm = validateSubscription(fieldsMap, errorMessages)

    // Submit Form
    if (validForm) {
      setNewsletterFormValid(true)

      if (
        ageGateSettings?.enabled &&
        !isLegalAge &&
        !cookies[props.data?.ageGateData?.cookieName]
      ) {
        setModalBoxContent(
          'ageGate',
          'modal-age-gate h-full w-[90%] lg:w-3/4 z-90 relative',
          props
        )
      } else {
        const formData = Object.fromEntries(
          new FormData(form.current).entries()
        )
        submitToHandler(formData)
      }
    }
  }

  useEffect(() => {
    if (
      newsletterFormValid === true &&
      formSubmitted === true &&
      (ageGateSettings?.enabled ? isLegalAge : true)
    ) {
      const formData = Object.fromEntries(new FormData(form.current).entries())
      submitToHandler(formData)
    }
  }, [formSubmitted])

  // Scroll to form based on URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const newsletterParam = params?.has('newsletter') ? true : false
    const newsletterAnchor = document.getElementById('newsletterSectionAnchor')

    if (typeof window !== 'undefined' && newsletterParam && newsletterAnchor) {
      window.document.body.insertAdjacentHTML(
        'afterbegin',
        '<div class="anchor-page-loading opacity-0"></div>'
      )
      document
        .querySelector('.anchor-page-loading')
        .classList.add('opacity-100')
      newsletterAnchor.scrollIntoView({ block: 'start' })
      setTimeout(function () {
        newsletterAnchor.scrollIntoView({ block: 'start' })
        document
          .querySelector('.anchor-page-loading')
          .classList.remove('opacity-100')
        setTimeout(function () {
          newsletterAnchor.scrollIntoView({ block: 'start' })
          document.querySelector('.anchor-page-loading').remove()
        }, 500)
      }, 1000)
    }
  }, [])

  // Prevalidate form to make sure every mandatory fields are filled
  function prevalidateForm() {
    if (
      wishlistField.current.checked &&
      legalField.current.checked &&
      consentField.current.checked
    ) {
      setCompleteForm(true)
    } else {
      setCompleteForm(false)
    }
  }

  return (
    <>
      <span
        id="newsletterSectionAnchor"
        className="newsletter-section-anchor absolute -top-32"
      />
      <div
        className={`newsletter-description ${
          newsletterData.image ? 'newsletter-image-wrap' : ''
        }`}
      >
        {newsletterData.image && (
          <Image
            media={newsletterData.image}
            className="newsletter-image-mobile gatsby-hidden-desktop"
          />
        )}
        {newsletterData.title && (
          <Heading
            text={
              newsletterData.title.text
                ? newsletterData.title.text
                : newsletterData.title
            }
            tag={
              newsletterData.title.tag
                ? newsletterData.title.tag
                : newsletterData.titleTag
                ? newsletterData.titleTag
                : 'h2'
            }
            className={`newsletter-title ${styles.title}`}
            animate={['opacity']}
          />
        )}
        {newsletterData.subTitle && (
          <Heading
            text={
              newsletterData.subTitle.text
                ? newsletterData.subTitle.text
                : newsletterData.subTitle
            }
            tag={
              newsletterData.subTitle.tag
                ? newsletterData.subTitle.tag
                : newsletterData.subTitleTag
                ? newsletterData.subTitleTag
                : 'h3'
            }
            className={`newsletter-subtitle ${styles.subtitle}`}
            animate={['opacity']}
          />
        )}
        {newsletterData.description && (
          <Markdown
            children={newsletterData.description}
            className={`newsletter-text ${styles.description}`}
            type="longText"
          />
        )}
      </div>
      <div className="flex flex-row">
        {newsletterData.image && (
          <Image
            media={newsletterData.image}
            className="newsletter-image gatsby-hidden-mobile"
          />
        )}
        <form
          className={`${
            formType === 'modal' ? 'newsletter-form-early' : 'newsletter-form'
          } ${styles.form}`}
          ref={form}
          onSubmit={submitForm}
          noValidate
        >
          <div
            className={`newsletter-fieldsWrapper ${styles.fieldsWrapper} ${
              completeForm ? '' : 'disabled'
            }`}
          >
            {/* Form Base fields */}
            <fieldset className={`${styles.emailFieldSet}`}>
              <label
                className={`${styles.emailLabel}`}
                htmlFor={`newsletter-email-${dataID}`}
              >
                {newsletterData.emailLabel}
              </label>
              <input
                ref={emailField}
                type="email"
                name="EMAIL"
                id={`newsletter-email-${dataID}`}
                onChange={debouncedChangeHandler(200)}
                placeholder={newsletterData.emailPlaceholder}
                className={`${styles.emailField}`}
                defaultValue={props.emailValue ? props.emailValue : ''}
              />
            </fieldset>
            {formType !== 'modal' ? (
              <Button type="submit" className={`${styles.submitButton}`}>
                <span>{newsletterData.emailButton}</span>
              </Button>
            ) : (
              <div className="separator-primary relative mx-auto mb-3 mt-6 w-full lg:mb-4 lg:mt-12 lg:w-3/4">
                <div className="h-[1px] w-full bg-primary lg:h-[2px]" />
                <i className="left"></i>
                <i className="right"></i>
              </div>
            )}
          </div>

          {/* Honeypot */}
          <div className="some-field" aria-hidden="true">
            <input
              ref={someField}
              type="text"
              name="5547c2b6258a4a891a7bf1bb4_08654e19bf"
              id="5547c2b6258a4a891a7bf1bb4_08654e19bf"
              tabIndex="-1"
              autocomplete="new-off"
            />
          </div>

          {/* Consent and Legal Fields */}
          <div
            ref={consentWrapper}
            className={`newsletter-checkboxes-wrapper ${
              formType === 'light' && 'h-0 overflow-hidden opacity-0'
            }`}
          >
            {newsletterData.wishlistText &&
              newsletterData.wishlistText !== null && (
                <div className={`newsletter-checkbox`}>
                  <label
                    htmlFor={`newsletter-wishlist-${dataID}`}
                    className="relative pl-12"
                  >
                    <input
                      ref={wishlistField}
                      className="mr-3"
                      type="checkbox"
                      name="wishlist"
                      value="true"
                      id={`newsletter-wishlist-${dataID}`}
                      onChange={prevalidateForm}
                    />
                    <Markdown children={newsletterData.wishlistText} />
                  </label>
                </div>
              )}
            {newsletterData.legalText && newsletterData.legalText !== null && (
              <div className={`newsletter-checkbox`}>
                <label
                  htmlFor={`newsletter-agreement-${dataID}`}
                  className="relative pl-12"
                >
                  <input
                    ref={legalField}
                    className="mr-3"
                    type="checkbox"
                    name="agreement"
                    value="true"
                    id={`newsletter-agreement-${dataID}`}
                    onChange={prevalidateForm}
                  />
                  <Markdown children={newsletterData.legalText} />
                </label>
              </div>
            )}
            {newsletterData.agreementText &&
              newsletterData.agreementText !== null && (
                <div className={`newsletter-checkbox`}>
                  <label
                    htmlFor={`newsletter-consent-${dataID}`}
                    className="relative pl-12"
                  >
                    <input
                      ref={consentField}
                      className="mr-3"
                      type="checkbox"
                      name="consent"
                      value="true"
                      id={`newsletter-consent-${dataID}`}
                      onChange={prevalidateForm}
                    />
                    <Markdown children={newsletterData.agreementText} />
                  </label>
                </div>
              )}
            {formType !== 'modal' && newsletterData?.ageRestrictionText && (
              <p className="mt-8 font-display text-base ">
                {newsletterData.ageRestrictionText}
              </p>
            )}
          </div>

          {/* Error Dialog */}
          <p
            ref={newsletterErrorField}
            className={`newsletter-message ${styles.errors}`}
          >
            <svg
              className="error-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
            </svg>
            <svg
              className="success-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-5.541 8.409l-1.422-1.409-7.021 7.183-3.08-2.937-1.395 1.435 4.5 4.319 8.418-8.591z" />
            </svg>
            <span ref={newsletterErrorFieldMessage}>
              {newsletterErrorMessage}
            </span>
          </p>

          {formType === 'modal' && (
            <>
              <Button
                type="submit"
                className={`btn button button-header relative z-10 inline-flex w-full items-center justify-center md:w-auto ${
                  completeForm ? '' : 'disabled'
                }`}
              >
                <div className="btn-content">
                  <i className="btn-decorator btn-decorator-left" />
                  <span>{newsletterData.emailButton}</span>
                  <i className="btn-decorator btn-decorator-right" />
                </div>
              </Button>
              {newsletterData?.ageRestrictionText && (
                <p className="mt-8 text-center font-display text-base">
                  {newsletterData.ageRestrictionText}
                </p>
              )}
            </>
          )}
        </form>
      </div>
    </>
  )
}

export default EarlyAccessForm
