import React, { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'

import useModalBox from '@base/utils/hooks/useModalBox'
import trackLink from '@base/utils/helpers/tracking/track-link'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'
import Button from '@base/parts/button'

import FormModal from './form-modal'

const NotificationBanner = ({ data, pageContext, globalData, className }) => {
  const [modal, openModalBox] = useModalBox()

  // Open CRM modal with parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const modalParams = params?.has('early-demo') ? true : false
    if (modalParams) {
      const modalData = {
        pageContext: pageContext,
        modalContent: {
          content: modalContent,
        },
      }
      setModalBoxContent(
        true,
        'dynamic',
        'modal-dynamic z-90 relative',
        modalData
      )
    }
  }, [])

  if (!data || data === null || !data.enabled) {
    return
  }

  const modalContent = () => {
    return (
      <FormModal
        data={data}
        pageContext={pageContext}
        globalData={globalData}
      />
    )
  }

  function setModalBoxContent(tracking, type, className, data) {
    if (tracking) {
      trackLink(data)
    }
    openModalBox(type, className, data)
  }

  const Banner = ({ enabled, type }) => {
    if (!type || !enabled) {
      return ''
    }

    let bannerWrapperClass = 'notification-banner-container'
    let bannerContentClass = 'notification-banner-content'

    return (
      <div className={`${bannerWrapperClass} relative mx-auto h-full w-full`}>
        <div
          className={`${bannerContentClass} relative h-full w-full p-6 lg:p-8 lg:w-auto`}
        >
          <div className="notification-banner-text flex pr-6 text-center font-nova text-[1rem] font-bold uppercase leading-tight tracking-widest text-white md:pr-0 md:text-base xl:text-md">
            {data.text && <Markdown children={data.text} />}
            {data.logo && (
              <Image
                media={data.logo[0] ? data.logo[0] : data.logo}
                className="h-7 w-auto md:h-9 xl:h-11"
              />
            )}
          </div>
          {data.linkText && (
            <Button
              trigger={data.type === 'crm' ? 'dynamicModal' : 'externalLink'}
              style="header"
              link={data.type !== 'crm' && data.linkUrl}
              pageContext={pageContext}
              modalContent={{
                content: modalContent,
              }}
              trackable={true}
              event={'navigation_banner'}
              eventSection={'navigation banner'}
            >
              <div className="btn-content">
                <i className="btn-decorator btn-decorator-left" />
                <span>{data.linkText}</span>
                <i className="btn-decorator btn-decorator-right" />
              </div>
            </Button>
          )}
        </div>
        <StaticImage
          src="../../assets/img/notification-banner-background.jpg"
          alt=""
          className={`gatsby-absolute top-0 z-under h-full w-full object-center`}
        />
      </div>
    )
  }

  if (data !== null) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.5,
          }}
          className={`notification-banner ${className}`}
          tabIndex={0}
        >
          <Banner enabled={data.enabled} type={data.type} />
        </motion.div>
      </AnimatePresence>
    )
  }
}

export default NotificationBanner
