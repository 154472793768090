import React from 'react'
import SVG from 'react-inlinesvg'

import diamond from '@img/icons/diamond.svg'

const NewsletterBackground = () => {
  return (
    <div className="newsletter-background overflow-hidden">
      <div className="w-full h-full mx-auto max-w-[100rem] hidden lg:flex items-center justify-between">
        <div className="newsletter-background-decoration newsletter-background-decoration-left -ml-40">
          <SVG
            onError={(error) => console.log(error.message)}
            cacheRequests={true}
            src={diamond}
            className="w-[27rem] h-[27rem]"
            keepStyle
          />
        </div>
        <div className="newsletter-background-decoration newsletter-background-decoration-right -mr-40 rotate-180">
          <SVG
            onError={(error) => console.log(error.message)}
            cacheRequests={true}
            src={diamond}
            className="w-[27rem] h-[27rem]"
            keepStyle
          />
        </div>
      </div>
    </div>
  )
}

export default NewsletterBackground
