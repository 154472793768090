import React from 'react'
import { motion } from 'framer-motion'
import SVG from 'react-inlinesvg'
import Link from '@base/parts/link'
import bhvr from '@base/assets/icons/logos/bhvr.svg'
import Image from '@base/parts/image'

const FooterNav = (props) => {
  const theme = props.theme

  return (
    <div className="mb-6 flex flex-col items-center lg:items-end justify-center text-base font-bold lg:mb-12 lg:flex-row">
      {props.links && (
        <nav arial-label="Footer Navigation" className="order-3 lg:order-1">
          <ul
            className="flex list-none flex-row flex-wrap items-center justify-center gap-x-12 gap-y-6 px-0 sm:my-0 sm:mr-10 sm:gap-y-0"
            name="Footer Navigation"
            role="menubar"
          >
            {props.links.map((navLink) => (
              <li
                className="flex items-center"
                key={'footer-link-' + navLink.id}
              >
                <Link
                  to={navLink.url}
                  newTab={navLink.newTab}
                  className={`
                    inline-flex items-center transition-all duration-300 text-xs lg:text-base mb-4 lg:mb-2
                    ${
                      theme &&
                      theme.text &&
                      `
                      ${
                        theme.text.default
                          ? `text-${theme.text.default}`
                          : 'text-white'
                      } 
                      ${
                        theme.text.hover
                          ? `hover:text-${theme.text.hover}`
                          : 'hover:text-primary'
                      }
                    `
                    }
                  `}
                >
                  {navLink.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
      <motion.a
        initial={{ opacity: 0, filter: 'blur(10px)' }}
        animate={{ opacity: 1, filter: 'blur(0px)' }}
        exit={{ opacity: 0, filter: 'blur(10px)' }}
        transition={{ duration: 0.5, delay: 0.5 }}
        title="Behaviour Interactive"
        href="https://www.bhvr.com/"
        target="_blank"
        rel="noreferrer"
        className={`
          order-1 mb-6 transition-all duration-300 lg:order-2 lg:mb-0
          ${
            theme.text &&
            `
            ${theme.text.default ? `text-${theme.text.default}` : 'text-white'} 
            ${
              theme.text.hover
                ? `hover:text-${theme.text.hover}`
                : 'hover:text-primary'
            }
          `
          }
        `}
      >
        <SVG
          onError={(error) => console.log(error.message)}
          cacheRequests={true}
          preProcessor={(code) =>
            code.replace(/fill=".*?"/g, 'fill="currentColor"')
          }
          src={bhvr}
          className="block h-auto w-44 fill-white lg:w-20"
        />
      </motion.a>

      {props.logo && props.logo !== null && (
        <motion.div
          initial={{ opacity: 0, filter: 'blur(10px)' }}
          animate={{ opacity: 1, filter: 'blur(0px)' }}
          exit={{ opacity: 0, filter: 'blur(10px)' }}
          transition={{ duration: 0.5, delay: 0.5 }}
          title={props.logo.alternativeText ? props.logo.alternativeText : ''}
          rel="noreferrer"
          className={`
                order-2 lg:order-3 transition-all duration-300 mb-12 lg:mb-0 lg:ml-8
                ${
                  theme.text &&
                  `
                  ${
                    theme.text.default
                      ? `text-${theme.text.default}`
                      : 'text-white'
                  } 
                  ${
                    theme.text.hover
                      ? `hover:text-${theme.text.hover}`
                      : 'hover:text-primary'
                  }
                `
                }
              `}
        >
          <Image className="block max-w-full lg:w-64" media={props.logo} />
        </motion.div>
      )}
    </div>
  )
}

export default FooterNav
